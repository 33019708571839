.css-1g24dm6-MuiInputBase-input-MuiOutlinedInput-input{
  height: 19px !important;
}
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes slide-up {
  0% { transform: translateY(100%); }
  100% { transform: translateY(0); }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay:hover {
  opacity: 1;
}

.overlay-content {
  text-align: center;
  padding: 20px;
}

.overlay-content > * {
  margin-bottom: 10px;
}
.form_width{
  width: 35%;
}
.form_low-heading{
  width:15%
}
.css-1f8sh1y{
  /* height: 450px !important; */
}
/* .css-dil72x-MuiToolbar-root{

} */


@media (max-width: 900px) {
  .form_width {
    width: 100%; /* Adjust width for mobile devices */
  }
  .form_low-heading{
    width:100%
  }
  .css-1f8sh1y{
    /* height: 900px !important; */
  }
}
@media (max-width: 600px) {
  .form_width {
    width: 100%; /* Adjust width for mobile devices */
  }
  .form_low-heading{
    width:100%
  }
  .css-1f8sh1y{
    /* height: 1800px !important; */
  }
}
