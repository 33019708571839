body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media (min-width: 600px){
  .css-mr79ci-MuiToolbar-root{
    min-height: 52px !important;
  }

}
@media (max-width: 600px) {
  .css-1cplggp-MuiImageList-root{
    grid-template-columns: repeat(1, 1fr) !important
  }
}